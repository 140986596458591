import MyEditModal from 'components/MyEditModal/MyEditModal';
import dealsApi from 'features/deals/deals.api';
import { DealDetail } from 'features/deals/models/DealDetail';
import { DealEdit, DealEditFactory } from 'features/deals/models/DealEdit';
import React, { useMemo } from 'react';
import DealEditFields from '../DealEditFields/DealEditFields';
import './DealEditModal.scss';

export default function DealEditModal({ model, close }: { model: DealDetail; close?: () => void }) {
    const [saveMutation, saveMutationState] = dealsApi.useDealUpdateMutation();

    const save = async (editModel: DealEdit) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    const workflowDetailQuery = dealsApi.useWorkflowDetailQuery(model.context.workflow.id);

    const dealEdit = useMemo(
        () =>
            DealEditFactory.create({
                workflowId: model.context.workflow.id,
                id: model.id,
                customerName: model.customerName,
                description: model.description,
                statusId: model.context.status.id,
                currentStatusId: model.context.status.id,
            }),
        [model],
    );

    return (
        <MyEditModal
            className="DealEditModal"
            model={dealEdit}
            isLoading={!dealEdit || workflowDetailQuery.isLoading}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit details"
            titleContext={model.tuid}
            mobileTitle={'Back'}
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    {workflowDetailQuery.data && (
                        <DealEditFields
                            editModel={editModel}
                            workflow={workflowDetailQuery.data}
                            phase={model.context.status.phase}
                            isSaving={isSaving}
                            updateField={updateField}
                        />
                    )}
                </>
            )}
        </MyEditModal>
    );
}
