import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import WorkflowPhase from 'features/deals/enums/WorkflowPhase';
import useWorkflowStatusOptions from 'features/deals/hooks/useWorkflowStatusOptions';
import { DealEdit } from 'features/deals/models/DealEdit';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import React from 'react';
import './DealEditFields.scss';

export default function DealEditFields({
    editModel,
    workflow,
    phase,
    isSaving,
    updateField,
}: {
    editModel: DealEdit;
    workflow: WorkflowDetail;
    phase: WorkflowPhase;
    isSaving: boolean;
    updateField: (data: Partial<DealEdit>) => void;
}) {
    const statusOptions = useWorkflowStatusOptions(workflow, phase);

    return (
        <div className="DealEditFields">
            <PropertyContainer
                layout="table"
                withInputSpacing
            >
                <PropertyEditSelect
                    label="Status"
                    options={statusOptions}
                    value={editModel.statusId}
                    onChange={statusId => updateField({ statusId })}
                    disabled={isSaving}
                    validationRequired
                />

                <PropertyEditText
                    label="Customer"
                    value={editModel?.customerName}
                    onChange={customerName => updateField({ customerName })}
                    disabled={isSaving}
                    validationRequired
                />
                <PropertyEditText
                    label="Description"
                    value={editModel?.description}
                    onChange={description => updateField({ description })}
                    disabled={isSaving}
                />
            </PropertyContainer>
        </div>
    );
}
