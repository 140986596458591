export type DealEdit = {
    id: string;
    workflowId: string;
    customerName: string;
    description: string;
    statusId: string;
    currentStatusId: string;
};

export class DealEditFactory {
    static create(args?: Partial<DealEdit> & { workflowId: string }) {
        const model: DealEdit = {
            id: args?.id ?? '',
            workflowId: args?.workflowId ?? '',
            customerName: args?.customerName ?? '',
            description: args?.description ?? '',
            statusId: args?.statusId ?? '',
            currentStatusId: args?.statusId ?? '',
        };

        return model;
    }
}
